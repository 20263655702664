// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckTaskReportList`
// 编辑
const editURL = `${API_CONFIG.butlerBaseURL}qualityManagement/UpdateScQualityCheckPlanTaskDetail`   // type ->  1：保存  2：完成
// 查询详情
const queryURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckTaskReportDetail`
// // 上传文件获取地址
// const uploadURL = `${API_CONFIG.origin}upload?module=quality`

// 上传
const uploadURL = `${API_CONFIG.uploadURL}/fileUploadNoLimit`

// 上传品质报告
const uploadReportURL = `${API_CONFIG.butlerBaseURL}qualityManagement/uploadReport`


export {
  getListURL,
  editURL,
  queryURL,
  uploadURL,
  uploadReportURL
}
