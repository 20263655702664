import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 计划类型
const planType = [
  {
    text: '品质督查',
    value: 1
  },
  {
    text: '项目自查',
    value: 2
  }
]

const {
  map: planTypeMap,
  setOps: planTypeOps
} = mapHelper.setMap(planType)

// 计划层级
const scopeType = [
  {
    text: '平台级',
    value: 1
  },
  {
    text: '公司级',
    value: 2
  },
  {
    text: '项目级',
    value: 3
  },
]

const {
  map: scopeTypeMap,
  setOps: scopeTypeOps
} = mapHelper.setMap(scopeType)

export {
  statusMap,
  setStatusOps,
  scopeTypeMap,
  scopeTypeOps,
  planTypeMap,
  planTypeOps,
}
